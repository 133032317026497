import React, { Component, Fragment } from 'react';

import {TwitterTweetEmbed} from 'react-twitter-embed';
import { StaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types';

import Styles from  './twitter.module.css';

class TwitterTimeLine extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      tweets: [],
    };

    this.fetchTweets = this.fetchTweets.bind(this)
  }

  componentDidMount() {
    const {account, numberOfTweets} = this.props;
    this.fetchTweets( account, numberOfTweets );

  }
  componentDidUpdate( prevProps ) {
    const {account, numberOfTweets} = this.props;
    if ( prevProps.account == account && prevProps.numberOfTweets == numberOfTweets ) {
      return;
    }
    this.fetchTweets( account, numberOfTweets );
  }
  fetchTweets( account, numberOfTweets ) {
    fetch( `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/gateway/twitter/1.1/statuses/user_timeline.json?exclude_replies=true&screen_name=${account}`, {
      method: 'GET'
    }).then( ( response ) => {
      return response.json();
    }).then( ( json )=> {
      if ( json[0]) {
        const tweets = json.slice( 0, numberOfTweets );
        this.setState({tweets});
      } else {
        this.setState({tweets: []});
      }
    }).catch( () => {
      this.setState({tweets: []});
    });
  }
  render() {
    const { numberOfTweets, title } = this.props;
//    const tweetWidth = this.state.device == 'mobile' ? '80%' : `${Math.floor( 97 / numberOfTweets )}%`
    return (
      <div className={Styles.container}>
          {this.state.tweets.map( ( tweet )=> (
              <TwitterTweetEmbed
                key={tweet.id}
                tweetId={tweet.id_str}
              onLoaded={tweetWidgetEl => {
				  if(tweetWidgetEl?.shadowRoot){
					  const CallToAction = tweetWidgetEl?.shadowRoot?.querySelector('.CallToAction')
					  CallToAction.style.display = 'none'
					  const tweetEl = tweetWidgetEl.shadowRoot.querySelector('.EmbeddedTweet')
					  tweetEl.style.border = 'none'
					  const tweetInside = tweetWidgetEl.shadowRoot.querySelector('.EmbeddedTweet-tweet')
					  tweetInside.style.border = 'none'
				  }

                }}
              />
          ) )}
        </div>
    );
  }
}

TwitterTimeLine.propTypes = {
  numberOfTweets: PropTypes.number,
  title: PropTypes.string,
  account: PropTypes.string
};



export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            social_networks {
              url
            }
          }
        }
      }
    `}
    render={data => {
      const twitterLink = data.wordpressAcfOptions.options.social_networks.filter((network) => network.url.includes('twitter'))[0].url
      const twitterAccount = twitterLink.split('/').pop()
      return (
        <TwitterTimeLine
          account={twitterAccount}
          {...props}
        />
      )
    }}
  />
);
